<header id="header__layout" class="w-full bg-white lg:p-3 py-3 px-5 relative z-[2]">
	<nav class="flex items-center justify-between w-[1440px] max-w-full mx-auto">
		<div class="flex gap-8 items-center">
			<div class="content__logo flex items-center gap-3 sm:pl-5 pl-4">
				<div class="lg:hidden block">
					<a class="btn__sidebar md:text-2xl text-xl block w-[25px] h-[25px] cursor-pointer">
						<i class="fa-light fa-bars-sort"></i>
					</a>
				</div>
				<div class="md:block max-w-full hidden">
					<img [src]="env.imgLogo" [alt]="env.title">
				</div>
			</div>
			<ul class="lg:flex hidden flex-wrap gap-4">
				@for (item of menu; track $index) {
					<li>
						@if (item.external) {
							<a [href]="item.url" target="_blank" class="font__title uppercase">{{ item.name }}</a>
						} @else {
							@if(item.submenu) {
								<dropdown-menu-cy [title]="item.name">
									<ul class="flex flex-col gap-4">
										@for (subitem of (item?.submenu ?? []); track $index) {
											<li>
												@if (subitem.external) {
													<a [href]="item.url" target="_blank" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
														<div class="w-full text-center">
															<img [src]="subitem.img" alt="icon" class="max-w-full">
														</div>
														<div class="w-full">
															<h5 class="font-medium text-base">{{ subitem.name }}</h5>
															<p *ngIf="subitem.desc" class="font-light text-sm">{{ subitem.desc }}</p>	
														</div>
													</a>
												} @else {
													<a [routerLink]="subitem.url" [queryParams]="subitem.params ?? {}" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
														<div class="w-full text-center">
															<img [src]="subitem.img" alt="icon" class="max-w-full">
														</div>
														<div class="w-full">
															<h5 class="font-medium text-base">{{ subitem.name }}</h5>
															<p class="font-light text-sm">{{ subitem.desc }}</p>
														</div>
													</a>
												}
											</li>
										}
									</ul>
								</dropdown-menu-cy>	
							} @else {
								<a [routerLink]="item.url" class="font__title uppercase">{{ item.name }}</a>
							}
						}
					</li>
				}
			</ul>
		</div>
		<div class="w-auto max-w-full">
			<ul class="list-none flex flex-wrap items-center gap-2">
				<li class="relative">
					<ng-container *ngIf="!authService.getAuth(); else elseAuth1">
						<div class="hidden sm:flex sm:flex-wrap sm:gap-2">
							<a routerLink="/auth/login">
								<button-cy type="button" addClass="font__title uppercase" label="Iniciar sesión" />
							</a>
							<a routerLink="/auth/register">
								<button-cy type="button" addClass="font__title uppercase btn__second" label="Registrarse" />
							</a>
						</div>
					</ng-container>
					<ng-template #elseAuth1>
						<ng-container *ngIf="user$ | async as _user; else elseAuthUser">
							<ng-template #titleCustom>
								<div class="normal-case text-gray-800 text-lg py-2 px-4 inline-flex items-center relative before:w-full before:h-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:top-1/2 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:scale-50 before:bg-gray-100 before:rounded-xl before:z-[-1] before:opacity-0 hover:before:opacity-100 hover:before:scale-100">
									<div class="w-[40px] mr-[10px]">
										<img [src]="_user.image ?? '/assets/img/user.png'" alt="User">
									</div>
									<span class="sm:flex sm:flex-col hidden max-w-[115px]">
										<span [title]="_user.name" class="uppercase text-sm block text-left leading-[normal] font-semibold text-ellipsis whitespace-nowrap overflow-hidden max-w-full">{{ _user.name }}</span>
										<!-- <span class="text-[13px] block text-left leading-[normal]">Dashboard</span> -->
									</span>
									<small><i class="fa-light fa-angle-down sm:ml-[8px]"></i></small>
								</div>
							</ng-template>
							<dropdown-menu-cy [title]="titleCustom" [customTitle]="true" position="right">
								<ul class="flex flex-col">
									@if(authService.getAuth()) {
										<li>
											<a routerLink="/account" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
												<div class="w-full text-center">
													<img src="/assets/img/header/account.png" alt="icon" class="max-w-full">
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Mi Cuenta</h5>
													<p class="font-light text-sm">Administra y controla tu perfil</p>
												</div>
											</a>
										</li>
										<li *ngIf="_user.memberships.length > 0">
											<div class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 w-full">
												<div class="w-full text-center">
													<img src="/assets/img/header/change.png" alt="icon" class="max-w-full">
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Cambiar perfil</h5>
													<p class="font-light text-sm">Seleccionar el perfil deseado a un click</p>
												</div>
											</div>
											<div class="w-full flex flex-col gap-2 mb-2">
												@for (item of _user.memberships; track $index) {
													<div (click)="changeProfile(item.id)" class="grid grid-cols-[minmax(0,20px)_minmax(0,1fr)] items-center cursor-pointer rounded transition-all" [ngClass]="(_user.membershipCurrent?.id == item.id) ? 'bg-gray-50 p-2' : ''">
														<div class="radio__field type__small">
															<input type="radio" [checked]="_user.membershipCurrent?.id == item.id">
															<span></span>
														</div>
														<div class="pl-2 desc__profile w-full type__small">
															<div class="profile__img">
																<img [defaultImage]="env.imgLogo" [errorImage]="env.imgLogo" [lazyLoad]="item?.image ?? ''" alt="Image">
															</div>
															<div class="profile__name">
																<h5 class="w-full truncate">{{ item?.name ?? '-' }}</h5>
																<div *ngIf="item?.suscripcion_name" class="status truncate">
																	{{ item?.suscripcion_name }}
																</div>
															</div>
														</div>
													</div>
												}
											</div>
										</li>
										<li>
											<a routerLink="/register-profile" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
												<div class="w-full text-center">
													<img src="/assets/img/header/add.png" alt="icon" class="max-w-full">
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Añadir perfil</h5>
													<p class="font-light text-sm">Registra un nuevo perfil</p>
												</div>
											</a>
										</li>
										<li>
											<button (click)="logout()" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
												<div class="w-full text-center">
													<img src="/assets/img/header/logout.png" alt="icon" class="max-w-full">
												</div>
												<div class="w-full text-left">
													<h5 class="font-medium text-base">Cerrar sesión</h5>
												</div>
											</button>
										</li>
									}
								</ul>
							</dropdown-menu-cy>
						</ng-container>
						<ng-template #elseAuthUser>
							<div class="hidden sm:flex sm:flex-wrap sm:gap-2">
								<a routerLink="/auth/login">
									<button-cy type="button" addClass="font__title uppercase" label="Iniciar sesión" />
								</a>
								<a routerLink="/auth/register">
									<button-cy type="button" addClass="font__title uppercase btn__second" label="Registrarse" />
								</a>
							</div>
						</ng-template>
					</ng-template>
				</li>
			</ul>
		</div>
	</nav>
</header>