import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TermsModalComponent } from '../../../../../../components/terms-modal/terms-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { getEnvironment } from '../../../../../../environment/environment';

@Component({
  selector: 'app-footer-1',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ]
})


export class Footer1Component implements OnInit {

  currentYear = new Date().getFullYear();
  year?: string;
  title?: string;

  env = getEnvironment();

  line1Images = [
    { url: 'https://fairplay.com.bo/marcas/puma', img: `assets/img/footer/line-1/puma.png`, name: 'Puma' },
    { url: 'https://www.suzuki.com.bo/', img: `assets/img/footer/line-1/suzuki.png`, name: 'Suzuki' },
    { url: 'https://www.cbn.bo/', img: `assets/img/footer/line-1/pacena.png`, name: 'Paceña' },
    { url: 'https://www.bg.com.bo/', img: `assets/img/footer/line-1/bg.png`, name: 'Banco Ganadero' },
  ];
  line2Images = [
    { url: 'https://www.fancesa.com/', img: `assets/img/footer/line-2/fancesa.png`, name: 'Fancesa' },
    { url: 'https://honorbolivia.com/', img: `assets/img/footer/line-2/honor.png`, name: 'Honor' },
    { url: 'https://www.lacascada.com.bo/', img: `assets/img/footer/line-2/villasanta.png`, name: 'Villa Santa' },
    // { url: 'https://yango.com/', img: `assets/img/footer/line-2/yango.png`, name: 'Yango' },
    // { url: 'https://www.pedidosya.com.bo/', img: `assets/img/footer/line-2/pedidosya.png`, name: 'PedidosYa' },
    // { url: 'https://www.tigo.com.bo/', img: `assets/img/footer/line-2/tigo.png`, name: 'Tigo' },
  ];
  line3Images = [
    { url: 'https://www.bago.com.bo/', img: `assets/img/footer/line-3/bago.png`, name: 'Bagó' },
    { url: 'https://breick.com.bo/', img: `assets/img/footer/line-3/breick.png`, name: 'breick' },
    { url: 'https://www.facebook.com/OrganicaSRL', img: `assets/img/footer/line-3/sabrositas.png`, name: 'Sabrositas' },
    { url: 'https://ketal.com.bo/', img: `assets/img/footer/line-2/ketal.png`, name: 'Ketal' },
    { url: 'https://libelula.bo/', img: `assets/img/footer/line-3/libelula.png`, name: 'Libélula' },
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }
    
  ngOnInit() {
    this.year = this.env.yearProyect;
    this.title = this.env.title;
  }

  irInicio() {
    this.router.navigateByUrl('/');
    // this.navController.navigateRoot('inicio');
  }

  openTermsModal() {
    const dialogRef = this.dialog.open(TermsModalComponent, {
      width: '1200px',
      disableClose: false,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);

    });
  }

}
