<footer class="sm:px-[3rem] px-4 pt-[3rem] bg-secondary">
	<div class="w-[900px] max-w-full mx-auto pb-[3rem]">
		<div class="w-full flex justify-center items-center sm:gap-4 gap-1 pb-4">
			@for (item of line1Images; track $index) {
				@if(item.img) {
					@if (item.url) {
						<a [href]="item.url" target="_blank">
							<img [src]="item.img" [alt]="item.name">
						</a>
					} @else {
						<img [src]="item.img" [alt]="item.name">
					}
				}
			}
		</div>
		<div class="w-full flex justify-around items-center sm:gap-4 gap-1 pb-4">
			@for (item of line2Images; track $index) {
				@if(item.img) {
					@if (item.url) {
						<a [href]="item.url" target="_blank">
							<img [src]="item.img" [alt]="item.name">
						</a>
					} @else {
						<img [src]="item.img" [alt]="item.name">
					}
				}
			}
		</div>
		<div class="w-full flex justify-center items-center sm:gap-4 gap-1">
			@for (item of line3Images; track $index) {
				@if(item.img) {
					@if (item.url) {
						<a [href]="item.url" target="_blank">
							<img [src]="item.img" [alt]="item.name">
						</a>
					} @else {
						<img [src]="item.img" [alt]="item.name">
					}
				}
			}
		</div>
	</div>
	<div class="w-[1440px] max-w-full mx-auto pt-4 lg:px-5 px-3 text-center border-t">
		<div class="w-[900px] max-w-full mx-auto pb-[3rem]">
			<nav class="flex flex-wrap justify-between">
				<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-left text-white">
					<li class="block mb-4">
						<h5 class="text-lg font__title uppercase">¿Podemos ayudarte?</h5>
					</li>
					<!-- <li class="block p-1">
						<p class="text-base font-light"><a href="#">Aviso legal</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a href="#">Envío</a></p>
					</li> -->
					<li class="block p-1">
						<p class="text-base font-light"><a routerLink="/secure-payment">Pago seguro</a></p>
					</li>
					<!-- <li class="block p-1">
						<p class="text-base font-light"><a href="#">Sobre nosotros</a></p>
					</li> -->
					<li class="block p-1">
						<p class="text-base font-light"><a (click)="openTermsModal()" class="cursor-pointer">Términos y condiciones</a></p>
					</li>
				</ul>
				<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-left text-white">
					<li class="block mb-4">
						<h5 class="text-lg font__title uppercase md:text-center">Contacto</h5>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center">
							<i class="fa-light fa-shop mr-1 text-[20px]"></i> Bolívar Store: <a href="tel:+59178831925" target="_blank">78831925</a>
						</p>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center">
							<img src="/assets/img/footer/whatsapp.png" class="mr-1" alt="WhatsApp"> WhatsApp: <a href="https://api.whatsapp.com/send?phone=59176797244" target="_blank">76797244</a>
						</p>
					</li>
					<li class="block p-1">
						<p class="font-light inline-flex flex-wrap items-center">
							<img src="/assets/img/footer/mail.png" class="mr-1" alt="E-mail"> E-mail: <a href="mailto:contacto@clubbolivar.com" target="_blank" [innerText]="'contacto@clubbolivar.com'"></a>
						</p>
					</li>
				</ul>
				<ul class="lg:w-auto sm:w-[250px] w-full max-w-full p-0 m-0 pt-4 block text-left text-white">
					<li class="block mb-4">
						<h5 class="text-lg font__title uppercase">Mi cuenta</h5>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a routerLink="/account">Mi cuenta</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a routerLink="/payments">Historal</a></p>
					</li>
					<!-- <li class="block p-1">
						<p class="text-base font-light"><a href="#">direcciones</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a href="#">Identidad</a></p>
					</li>
					<li class="block p-1">
						<p class="text-base font-light"><a href="#">Acceso</a></p>
					</li> -->
				</ul>
			</nav>
		</div>
	</div>
	<div class="w-[1024px] max-w-full mx-auto p-4 lg:px-5 px-3 text-center border-t border-[#737373]">
		<p class="font-medium text-sm text-gray-400">&copy; Copyright {{ year ?? currentYear }} {{ title ?? 'Solunes Digital' }} | Todos los derechos reservados.</p>
	</div>
</footer>