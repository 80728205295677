import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import Swal from 'sweetalert2';
import { NotificationService } from '../../../../../../services/notification.service';
import { Observable, Subscription } from 'rxjs';
import { Data, XtrasService } from '../../../../../../services/xtras.service';
import { AuthService, UserType } from '../../../../../../services/auth.service';
import { UserModel } from '../../../../../../services/user-model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCapYeiComponent } from '../../../../../../components/button/button.component';
import { DropdownMenuCapYeiComponent } from '../../../../../../components/dropdown-menu/drowdown-menu.component';
declare var $: any;

@Component({
  selector: 'app-footer-app-1',
  templateUrl: './footer-app.component.html',
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    DropdownMenuCapYeiComponent,
  ]
})
export class FooterApp1Component implements OnInit {

  private unsubscribe: Subscription[] = [];

  back_location = null;

  user$: Observable<UserType>;
  data$: Observable<Data>;
  
  services = [];
  notifications = [];
  unread_notifications_count = 0;

  shop = [
    { img: 'assets/img/header/shop.png', url: '/', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
    { img: 'assets/img/header/card.png', url: '/', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
    { img: 'assets/img/header/card.png', url: '/', external: false, name: 'Abonos', desc: 'Nore, sur, general, preferencia y butaca' },
    { img: 'assets/img/header/sound.png', url: '/', external: false, name: 'Eventos', desc: 'Partidos y eventos importantes' },
    { img: 'assets/img/header/file.png', url: '/', external: false, name: 'Entradas', desc: 'Partidos' },
  ];

  payments = [
    { img: 'assets/img/header/card.png', url: '/', external: false, name: 'Pagos pendientes', desc: 'Si tienes alguna deuda puedes hacerlo aquí' },
    { img: 'assets/img/header/book.png', url: '/', external: false, name: 'Historial y facturas', desc: 'Revisa todos los movimientos que hiciste en Socio Centenario. También si quieres la documentación de algún pago realizado' },
  ];

  club = [
    { img: 'assets/img/header/graduation.png', url: '/', external: false, name: 'Historia', desc: 'Todo nuestro recorrido en un solo lugar' },
    { img: 'assets/img/header/file-search.png', url: '/', external: false, name: 'Noticias', desc: 'Mira todo lo que hemos realizado estos últimos días' },
    { img: 'assets/img/header/file-check.png', url: '/', external: false, name: 'Fixture 2024', desc: 'Planea tus próximos días para apoyarnos en la cancha' },
    { img: 'assets/img/header/trophy.png', url: '/', external: false, name: 'Tablas de posiciones', desc: 'Revisa en qué posiciones estamos en los eventos nacionales e internacionales' },
    { img: 'assets/img/header/users.png', url: '/', external: false, name: 'Plantel', desc: 'Revisa el perfil de cada uno de nuestros integrantes' },
  ];

  constructor(
    private toast: NotificationService,
    public xServices: XtrasService,
    public authService: AuthService,
    public userModel: UserModel,
    private router: Router,
  ) {
    this.data$ = this.xServices.sharingObservable;
    this.user$ = this.authService.user$;
  }

  ngOnInit() {
  }

  logout(){
    Swal.fire({
      title: 'Cerrar Sesión',
      text: '¿Desea cerrar su Sesión?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result: any) => {
      if(result.value) {
        this.doLogout();
      } else if(result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  doLogout() {
    this.authService.logout();
    this.toast.success('Sesión cerrada', 'Cerraste tu sesión exitosamente', 4000);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  closeMenu() {
    // Todo: Añadir servicio en el backend para realizar esta acción
    // const subscr = this.http.getRequest(SettingsProvider.getUrl(`mark-notifications-as-read`)).subscribe((result: any) => {
    //   if(result['status']) {
    //     this.load.dismiss();
    //     this.authService.getUserByToken().subscribe();
    //   } else {
    //     this.load.dismiss();
    //     this.toast.error('Error al leer las notificaciones', result['message'], 4000);
    //   }
    // }, () => {
    //   this.load.dismiss();
    //   this.toast.error('Error al obtener los datos', 'No se pudo obtener los datos, verifíque su conexión a internet e intente nuevamente.', 4000);
    //   // this.errorPage();
    // });
    // this.unsubscribe.push(subscr);
  }

  toggleSidebarDesk() {
    $('body').toggleClass('hide__menu');
  }

  toLogin() {
    this.router.navigateByUrl('/auth/login');
  }

  toRegister() {
    this.router.navigateByUrl('/auth/register');
  }
}
